import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { computed, makeObservable } from 'mobx';
import { date, deserialize, list, object, serializable } from 'serializr';
import { formatLocaleDateTime } from 'utils/dateHelpers';

import { CategoricalPropertyCoding } from './CategoricalPropertyCoding.entity';
import { InformationModel } from './InformationModel.entity';
import { PatientAges } from './PatientAges.entity';

export class Description {
  @serializable
  ageConstraints: string = 'all';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable
  studyId?: number = 0;

  @serializable
  descriptionId: number = 0;

  @serializable(object(InformationModel))
  descriptionStatus: InformationModel = new InformationModel();

  @serializable
  patientId: number = 0;

  @serializable
  patientDetailsId: number = 0;

  @serializable(object(PatientAges))
  patientAges: PatientAges = new PatientAges();

  @serializable
  eegSummary: string | null = '';

  @serializable
  clinicalComment: string | null = '';

  @serializable
  isActive: boolean = false;

  @serializable
  isCompleted: boolean = false;

  @serializable
  pdf?: string = '';

  @serializable
  revision!: number;

  @serializable(list(object(CategoricalPropertyCoding)))
  propertyCodings: CategoricalPropertyCoding[] = [];

  @serializable(object(InformationModel))
  latestMeal: InformationModel = new InformationModel();

  @serializable
  recordingNotes: string | null = '';

  constructor() {
    makeObservable(this);
  }

  private getDateFromInformationModel(informationModel?: InformationModel) {
    return informationModel?.secondaryValue
      ? new Date(informationModel.secondaryValue)
      : undefined;
  }

  get formattedLatestMeal() {
    const date = this.getDateFromInformationModel(this.latestMeal);

    return formatLocaleDateTime(date, '', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  @computed
  get eegType() {
    const eegPropertyCoding = this.propertyCodings.find(
      ({ propertyTypeModel }) =>
        propertyTypeModel?.propertyTypeId === DescriptionPropertyTypes.EEGType
    );
    if (eegPropertyCoding) {
      return eegPropertyCoding.propertyCodeModel.translatedName.eitherValue;
    }
    return '';
  }

  @computed
  get diagnosticSignificance() {
    const eegPropertyCoding = this.propertyCodings.find(
      ({ propertyTypeModel }) =>
        propertyTypeModel?.propertyTypeId ===
        DescriptionPropertyTypes.DiagnosticSignificance
    );
    if (eegPropertyCoding) {
      return {
        value: eegPropertyCoding.propertyCodeModel.translatedName.eitherValue,
        freeText: eegPropertyCoding.freeText
      };
    }
    return null;
  }

  @computed
  get isNeonatal() {
    const maxAgeInlMonths = 12;

    return (
      Number.isInteger(this.patientAges.chronologicalAge?.ageInMonths) &&
      this.patientAges.chronologicalAge?.ageInMonths <= maxAgeInlMonths
    );
  }

  @computed
  get correctedAgeAtStudyTime() {
    return this.patientAges.correctedAge?.displayText.eitherValue || '-';
  }

  @computed
  get postMenstrualAgeAtStudyTime() {
    return this.patientAges.postmenstrualAge?.displayText.eitherValue || '-';
  }

  static deserialize(json: Object | string) {
    return deserialize(Description, json);
  }

  static deserializeAsList(list: Description[]): Description[] {
    return list.map(Description.deserialize);
  }
}

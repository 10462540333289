import React, { useCallback, useEffect, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonTheme,
  GeneralError,
  IconType,
  LoadingOverlay,
  StatusIndicatorStatus,
  SystemMessage,
  SystemMessageTheme,
  useCustomTranslation
} from '@holberg/ui-kit';
import { ErrorTheme } from '@holberg/ui-kit';
import cn from 'classnames';
import { ErrorScreen } from 'components/ErrorScreen';
import { NavigationHeader } from 'components/NavigationHeader';
import { SnackBar } from 'components/SnackBar';
import {
  ReaderErrorMessage,
  ReaderErrorType
} from 'enums/ReaderErrorType.enum';
import { RealTimeUpdateSendMessages } from 'enums/RealTimeUpdateType.enum';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { usePageMatch } from 'hooks/usePageMatch';
import { observer } from 'mobx-react-lite';
import { createShortcutCombination } from 'services/keyboardShortcuts/helpers';
import { shortcutsBaseKeys } from 'services/keyboardShortcuts/shortcutsBaseKeys';
import { RTUManager } from 'services/RealTimeUpdatesManager';
import { combineSaveStatus } from 'utils/combineSaveStatus';

import styles from './Layout.module.scss';

interface Props {
  className?: string;
  getContentClassName?: (hasConnectionIssues: boolean) => string;
}

export const Layout: React.FC<Props> = observer(
  ({ className, children, getContentClassName }) => {
    const i18nStore = useStore(StoreType.I18n);
    const authStore = useStore(StoreType.Auth);
    const messagesStore = useStore(StoreType.Messages);
    const findingsStore = useStore(StoreType.Findings);
    const studyDetailsStore = useStore(StoreType.StudyDetails);
    const patientDetailsStore = useStore(StoreType.PatientDetails);
    const descriptionsStore = useStore(StoreType.Descriptions);
    const patientLockDetailsStore = useStore(StoreType.PatientLockDetails);
    const rtuStore = useStore(StoreType.RealTimeUpdates);
    const rtuConfig = rtuStore.realTimeUpdatesConfig;
    const { isReaderConnected, isHubConnected, isTerminated } = rtuConfig;
    const { t } = useCustomTranslation();

    const isPatientOverviewPage = usePageMatch(Routes.PatientOverview);
    const isStudyPage = usePageMatch(Routes.Study);
    const saveStatus = useMemo(
      () =>
        combineSaveStatus([
          studyDetailsStore.saveStatus,
          patientDetailsStore.saveStatus,
          descriptionsStore.saveStatus,
          findingsStore.saveStatus
        ]),
      [
        studyDetailsStore.saveStatus,
        patientDetailsStore.saveStatus,
        descriptionsStore.saveStatus,
        findingsStore.saveStatus
      ]
    );
    useEffect(() => {
      if (authStore.isAuthenticated) {
        i18nStore.loadTranslation();
      }

      return () => messagesStore.clearPageErrors();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      //release patient lock when not in patientOverview or studyDetailsPage
      const lastOpenedPatientId = patientLockDetailsStore.lastOpenedPatientId;
      if (!isStudyPage && !isPatientOverviewPage && lastOpenedPatientId) {
        const patientLockDetails = patientLockDetailsStore.patientLockDetails.get(
          lastOpenedPatientId
        );
        RTUManager.sendMessage(RealTimeUpdateSendMessages.PatientClosed, {
          patientId: lastOpenedPatientId
        });

        if (patientLockDetails?.sessionHasLock) {
          patientLockDetailsStore.sendPatientLockMessage(
            lastOpenedPatientId,
            RealTimeUpdateSendMessages.ReleasePatientEditLock
          );
        }
        patientLockDetailsStore.reset();
      }
    }, [isPatientOverviewPage, isStudyPage, patientLockDetailsStore]);

    const contentClassName = useMemo(
      () =>
        getContentClassName
          ? getContentClassName(messagesStore.hasConnectionIssues)
          : undefined,

      [messagesStore.hasConnectionIssues, getContentClassName]
    );

    const reloadPage = useCallback(() => window.location.reload(), []);

    const isDataSaving = saveStatus === StatusIndicatorStatus.Loading;

    const systemMsgVisible = useMemo(
      () => !messagesStore.hasConnectionIssues && messagesStore.message,
      [messagesStore.hasConnectionIssues, messagesStore.message]
    );

    const removeMessage = useCallback(() => {
      messagesStore.removeMessage();
    }, [messagesStore]);

    const getReaderConnectionError = () => {
      if (!isHubConnected || isTerminated) {
        return ReaderErrorMessage[ReaderErrorType.ReaderAuthFailed];
      } else if (!isReaderConnected && !isDataSaving) {
        return ReaderErrorMessage[ReaderErrorType.ReaderDisconnected];
      }

      return '';
    };

    const readerConnectionError = getReaderConnectionError();

    if (readerConnectionError) {
      return <ErrorScreen errorText={t(readerConnectionError)} />;
    }

    return (
      <>
        <LoadingOverlay loading={!isReaderConnected && isDataSaving}>
          <div className={cn(styles.layout, className)}>
            <NavigationHeader />
            <div className={contentClassName}>
              <SnackBar className={styles['snack-bar']} />
              {messagesStore.generalError ? (
                <GeneralError
                  icon={IconType.Empty}
                  title={messagesStore.generalError.fullMessage}
                  className={styles['error-wrapper']}
                  theme={ErrorTheme.Secondary}
                >
                  <Button
                    title={t('Reload page')}
                    onClick={reloadPage}
                    theme={ButtonTheme.Underlined}
                  />
                </GeneralError>
              ) : (
                children
              )}
            </div>
          </div>
        </LoadingOverlay>
        <SystemMessage
          visible={!!systemMsgVisible}
          theme={messagesStore.message?.theme || SystemMessageTheme.Error}
          title={t(messagesStore.message?.title || '')}
          onClose={removeMessage}
        />
      </>
    );
  }
);

export const withLayout = <P extends {}>(
  getContentClassName?: (hasConnectionIssues: boolean) => string
) => (WrappedComponent: React.ComponentType<P>) => {
  const Wrapped: React.FC<P> = (props) => {
    const { goBack, goForward } = useHistory();

    useHotkeys(
      createShortcutCombination({
        baseKey: shortcutsBaseKeys.CMD,
        key: ']'
      }),
      (event) => {
        event.preventDefault();

        goBack();
      }
    );

    useHotkeys(
      createShortcutCombination({
        baseKey: shortcutsBaseKeys.CMD,
        key: '['
      }),
      (event) => {
        event.preventDefault();

        goForward();
      }
    );

    return (
      <Layout getContentClassName={getContentClassName}>
        <WrappedComponent {...props} />
      </Layout>
    );
  };

  return Wrapped;
};

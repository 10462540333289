import React, { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconType,
  rebuildTooltips,
  TextBadge,
  Tooltip,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import {
  ConfirmationTitle,
  useConfirmationContext
} from 'components/FindingsConfirmationModal';
import { useNameSelectionContext } from 'components/NameSelectionModal/NameSelectionContext';
import { Description } from 'entities/Description.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { createShortcutCombination } from 'services/keyboardShortcuts/helpers';
import { shortcutsBaseTitles } from 'services/keyboardShortcuts/shortcutsBaseKeys';
import { getEventsIdentifier } from 'stores/findings/helpers';

import styles from './FindingsActionBar.module.scss';

interface Props {
  activeDescriptionId: Description['descriptionId'];
  onExpandUnclassifiedExamples: () => void;
  unclassifiedExamplesCollapsed?: boolean;
}

export const FindingsActionBar: React.FC<Props> = observer(
  ({
    activeDescriptionId,
    onExpandUnclassifiedExamples,
    unclassifiedExamplesCollapsed = false
  }) => {
    const { t } = useCustomTranslation();
    const { id: studyId } = useParams<{ id: string }>();

    const findingsStore = useStore(StoreType.Findings);

    const discardSelections = useCallback(
      () => findingsStore.selectionState.discardSelections(),
      [findingsStore.selectionState]
    );

    const {
      state: nameSelectionState,
      onOpen: onNameSelectionOpen,
      onClose: onNameSelectionClose
    } = useNameSelectionContext()!;
    const confirmationContext = useConfirmationContext();

    const selectedExamples = findingsStore.selectionState.selectedExamples;
    const actionBarState = findingsStore.selectionState.actionBarState;

    useEffect(() => {
      rebuildTooltips();
    }, [
      findingsStore.selectionState.selectionSize,
      actionBarState.addUnlinkedFindingAvailable,
      actionBarState.simultaneousAvailable,
      actionBarState.undoSimultaneousAvailable,
      actionBarState.unclassifyAvailable
    ]);

    const createEventCoding = useCallback(
      async (eventCodeId: number) => {
        await findingsStore.createEventCoding({
          data: {
            eventCodeId,
            eventIds: selectedExamples.length
              ? selectedExamples.map((example) => example.eventId)
              : []
          },
          studyId: Number(studyId),
          descriptionId: activeDescriptionId,
          identifier: selectedExamples.length
            ? `examples-classify-${getEventsIdentifier(selectedExamples)}`
            : `add-finding`
        });

        findingsStore.selectionState.discardSelections();
      },
      [activeDescriptionId, findingsStore, selectedExamples, studyId]
    );

    const onAddFinding = useCallback(() => {
      onNameSelectionOpen({
        title: t('Classify'),
        onSelect: (eventCodeId) => {
          if (
            findingsStore.hasActiveOnlyOneEventCoding(
              activeDescriptionId,
              eventCodeId
            )
          ) {
            confirmationContext?.onOpen({
              title: t(ConfirmationTitle.Add_Second_OnlyOneEventCoding),
              submitButtonTitle: t('Yes, sure'),
              onSubmit: () => createEventCoding(eventCodeId)
            });
          } else {
            createEventCoding(eventCodeId);
          }

          onNameSelectionClose();
        }
      });
    }, [
      onNameSelectionOpen,
      t,
      onNameSelectionClose,
      findingsStore,
      activeDescriptionId,
      confirmationContext,
      createEventCoding
    ]);

    useHotkeys(
      createShortcutCombination({ key: 'a' }),
      (event) => {
        event.preventDefault();

        if (
          findingsStore.selectionState.actionBarState
            .addUnlinkedFindingAvailable &&
          !nameSelectionState.isOpen
        ) {
          return onAddFinding();
        }
        if (nameSelectionState.isOpen) {
          return onNameSelectionClose();
        }
      },
      {},
      [
        findingsStore.selectionState.actionBarState.addUnlinkedFindingAvailable,
        nameSelectionState.isOpen,
        onAddFinding
      ]
    );

    useHotkeys(
      createShortcutCombination({ key: 'esc' }),
      (event) => {
        event.preventDefault();

        if (!!findingsStore.selectionState.selectionSize && discardSelections) {
          discardSelections();
        }
      },
      { enableOnTags: ['INPUT'] }
    );

    const selectedEventCodings = [
      ...findingsStore.selectionState.selectedFindings
    ].map((value) => value.item);

    const onUndoSimultaneous = useCallback(() => {
      findingsStore.undoSimultaneous(activeDescriptionId, selectedEventCodings);
    }, [selectedEventCodings, activeDescriptionId, findingsStore]);

    const onMakeSimultaneous = useCallback(() => {
      findingsStore.makeSimultaneous(activeDescriptionId, selectedEventCodings);
    }, [selectedEventCodings, activeDescriptionId, findingsStore]);

    const deleteEventCodings = useCallback(async () => {
      await findingsStore.deleteEventCodings(
        activeDescriptionId,
        findingsStore.selectionState.selectedFindingsIds
      );
      findingsStore.selectionState.discardFindingsSelections();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      activeDescriptionId,
      findingsStore.selectionState.selectedFindingsIds,
      studyId
    ]);

    const onUnclassifyFindings = useCallback(() => {
      const hasClassifiedFinding = findingsStore.selectionState.selectedFindings.some(
        ({ isToBeDefined }) => !isToBeDefined
      );
      const hasLinkedFinding = findingsStore.selectionState.selectedFindings.some(
        ({ isLinked }) => isLinked
      );

      if (hasClassifiedFinding || hasLinkedFinding) {
        confirmationContext?.onOpen({
          title: t(
            hasClassifiedFinding
              ? ConfirmationTitle.Unclassify_Classified
              : ConfirmationTitle.Unclassify_Linked
          ),
          submitButtonTitle: t('Yes, unclassify'),
          onSubmit: deleteEventCodings
        });
      } else {
        deleteEventCodings();
      }
    }, [
      t,
      findingsStore.selectionState.selectedFindings,
      confirmationContext,
      deleteEventCodings
    ]);

    const deleteEvents = useCallback(async () => {
      await findingsStore.deleteEvents(
        activeDescriptionId,
        findingsStore.selectionState.selectedClassifiedExamplesIds,
        findingsStore.selectionState.selectedExamples.map(
          (example) => example.eventCodingId
        )
      );
      findingsStore.selectionState.discardExamplesSelections();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      activeDescriptionId,
      findingsStore.selectionState.selectedClassifiedExamplesIds,
      studyId
    ]);

    const onUnclassifySelection = useCallback(() => {
      const hasSelectedFindings = !!findingsStore.selectionState
        .selectedFindingsIds.length;

      if (hasSelectedFindings) {
        onUnclassifyFindings();
      } else {
        deleteEvents();
      }
    }, [
      findingsStore.selectionState.selectedFindingsIds.length,
      onUnclassifyFindings,
      deleteEvents
    ]);

    const onDeletingExamples = () => {
      confirmationContext?.onOpen({
        title: ConfirmationTitle.Delete_Example,
        submitButtonTitle: 'Yes, delete',
        onSubmit: () =>
          findingsStore.deleteEEGMarker(
            activeDescriptionId,
            findingsStore.selectionState.getSelectedExamplesIds
          )
      });
    };

    return (
      <div
        data-testid='finding-action-bar'
        className={styles['findings-action-bar']}
      >
        <div className={styles['left-container']}>
          <Tooltip
            data={[
              {
                mainTooltip: (
                  <span>
                    {t('Expand')}
                    <TextBadge
                      title={shortcutsBaseTitles.CMD}
                      className={styles['text-badge']}
                    />
                    +
                    <TextBadge title={t(';')} />
                  </span>
                )
              }
            ]}
          >
            <Button
              data-testid={'btn-expand-examples'}
              onClick={onExpandUnclassifiedExamples}
              size={ButtonSize.Small}
              icon={IconType.SkipArrow}
              theme={ButtonTheme.SecondaryTransparent}
              className={cn(
                styles['btn-collapse'],
                unclassifiedExamplesCollapsed && styles.visible
              )}
              iconClassName={styles['icon-collapse']}
            />
          </Tooltip>
          {!!findingsStore.selectionState.selectionSize ? (
            <Tooltip
              data={[
                {
                  mainTooltip: (
                    <span>
                      {t('Discard selection')}
                      <TextBadge
                        title={shortcutsBaseTitles.ESC}
                        className={styles['text-badge']}
                      />
                    </span>
                  )
                }
              ]}
            >
              <Button
                icon={IconType.Close}
                size={ButtonSize.Small}
                onClick={discardSelections}
                title={t('Discard selection')}
                theme={ButtonTheme.SecondaryTransparent}
              />
            </Tooltip>
          ) : (
            <span>{t('Hover over items to see available actions')}</span>
          )}
        </div>
        <div className={styles['right-container']}>
          {findingsStore.selectionState.selectionSize === 1 && (
            <span>{t('Select one more item to see available actions')}</span>
          )}
          {actionBarState.addUnlinkedFindingAvailable && (
            <Tooltip
              data={[
                {
                  mainTooltip: (
                    <span>
                      {t('Add unlinked finding')}
                      <TextBadge title='A' />
                    </span>
                  )
                }
              ]}
            >
              <Button
                onClick={onAddFinding}
                icon={IconType.Plus}
                size={ButtonSize.Small}
                theme={ButtonTheme.Secondary}
                title={t('Add unlinked finding')}
                className={styles['action-item']}
              />
            </Tooltip>
          )}
          {actionBarState.addFromSelectionAvailable && (
            <>
              <Button
                onClick={onAddFinding}
                icon={IconType.Plus}
                size={ButtonSize.Small}
                theme={ButtonTheme.Secondary}
                title={t('Add finding from selection')}
                className={styles['action-item']}
              />
              <Tooltip
                data={[
                  {
                    mainTooltip: (
                      <span>
                        {t(
                          'Delete selected examples and the corresponding markers in the EEG'
                        )}
                      </span>
                    )
                  }
                ]}
              >
                <Button
                  title={t('Delete')}
                  icon={IconType.Trash}
                  size={ButtonSize.Small}
                  onClick={onDeletingExamples}
                  theme={ButtonTheme.Caution}
                  className={styles['action-item']}
                />
              </Tooltip>
            </>
          )}
          {actionBarState.simultaneousAvailable && (
            <Tooltip
              data={[
                {
                  mainTooltip: (
                    <span>
                      {t('Simultaneous')}
                      {/* <TextBadge title={t('S')} /> */}
                    </span>
                  )
                }
              ]}
            >
              <Button
                title={t('Simultaneous')}
                icon={IconType.Simultaneous}
                size={ButtonSize.Small}
                className={styles['action-item']}
                theme={ButtonTheme.Highlighted}
                onClick={onMakeSimultaneous}
              />
            </Tooltip>
          )}
          {actionBarState.undoSimultaneousAvailable && (
            <Tooltip
              data={[
                {
                  mainTooltip: (
                    <span>
                      {t('Undo simultaneous')}
                      {/* <TextBadge title={t('S')} /> */}
                    </span>
                  )
                }
              ]}
            >
              <Button
                onClick={onUndoSimultaneous}
                title={t('Undo simultaneous')}
                icon={IconType.UndoSimultaneous}
                size={ButtonSize.Small}
                className={styles['action-item']}
                theme={ButtonTheme.Highlighted}
              />
            </Tooltip>
          )}
          {actionBarState.unclassifyAvailable && (
            <Tooltip
              data={[
                {
                  mainTooltip: (
                    <span>
                      {t('Unclassify')}
                      {/* <TextBadge
                        className={styles['text-badge']}
                        title={shortcutsBaseTitles.DELETE}
                      /> */}
                    </span>
                  )
                }
              ]}
            >
              <Button
                title={t('Unclassify')}
                icon={IconType.Close}
                size={ButtonSize.Small}
                onClick={onUnclassifySelection}
                theme={ButtonTheme.Caution}
              />
            </Tooltip>
          )}
        </div>
      </div>
    );
  }
);

import { StoreType } from 'enums/StoreType.enum';

import { useStore } from './store';

export const useCurrentSessionHasLock = () => {
  const patientLockDetailsStore = useStore(StoreType.PatientLockDetails);

  const currentSessionHasLock = (patientId: number) => {
    const patientLockDetails = patientLockDetailsStore.patientLockDetails.get(
      patientId
    );
    return patientLockDetails?.sessionHasLock || false;
  };

  return { currentSessionHasLock };
};

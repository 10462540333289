import { deserialize, list, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class EEGMarkerType {
  @serializable
  markerTypeId: number = 0;

  @serializable
  markerType: string = '';

  @serializable(list(object(InformationModel)))
  markerShortNames: InformationModel[] = [new InformationModel()];

  static deserialize(json: Object | string) {
    return deserialize(EEGMarkerType, json);
  }

  static deserializeAsList(list: EEGMarkerType[]): EEGMarkerType[] {
    return list.map(EEGMarkerType.deserialize);
  }
}

import { BaseBlock, ReportDataFacade } from '@holberg/ui-kit';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { Description } from 'entities/Description.entity';
import { DescriptionPropertyCodingCreateDTO } from 'entities/DescriptionPropertyCodingCreateDTO.entity';
import { DescriptionPropertyCodingUpdateDTO } from 'entities/DescriptionPropertyCodingUpdateDTO.entity';
import { DescriptionStatus } from 'entities/DescriptionStatus.entity';
import { DescriptionUpdateDTO } from 'entities/DescriptionUpdateDTO.entity';
import { EEGMarkerType } from 'entities/EEGMarkerType.entity';
import { Event } from 'entities/Event.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { EventCodingCreateDTO } from 'entities/EventCodingCreateDTO.entity';
import { EventCodingHeadModel } from 'entities/EventCodingHeadModel.entity';
import { EventCodingUpdateDTO } from 'entities/EventCodingUpdateDTO.entity';
import { EventPropertyCoding } from 'entities/EventPropertyCoding.entity';
import { EventPropertyCodingUpdateDTO } from 'entities/EventPropertyCodingUpdateDTO.entity';
import { GenericEventTree } from 'entities/GenericEventTree.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { Report } from 'entities/Report.entity';
import { ShoppingCart } from 'entities/ShoppingCart';
import { Study } from 'entities/Study.entity';
import { StudyUpdateDTO } from 'entities/StudyUpdateDTO.entity';
import { UpdatedEventPropertyCoding } from 'entities/UpdatedEventPropertyCoding.entity';
import { HeadModelTemplate } from 'enums/HeadModelTemplate.enum';
import queryString from 'query-string';
import { ApiBase } from 'services/API/ApiBase';
import { CommonPropertyType } from 'stores/property-type-codes';

class StudyApi extends ApiBase {
  loadStudiesListByPatientId(patientId: PatientDetails['patientId']) {
    const query = queryString.stringify({
      patientId
    });

    return this.client.get<Study[]>(`/studies?${query}`);
  }

  loadStudyDetails(studyId: Study['studyId']) {
    return this.client.get<Study>(`/studies/${studyId}`);
  }

  createNewReport(recordingIds: number[]) {
    return this.client.post(
      `/reportScopes?recordingIds=${recordingIds.join(',')}`
    );
  }

  loadDescriptionDetails(descriptionId: Description['descriptionId']) {
    return this.client.get<Study>(`/descriptions/${descriptionId}`);
  }

  loadDescriptionsListByStudyId(studyId: Study['studyId']) {
    return this.client.get<Description[]>(`/studies/${studyId}/descriptions`);
  }

  loadEvents(descriptionId: Description['descriptionId']) {
    return this.client.get<Event[]>(`/descriptions/${descriptionId}/events`);
  }

  updateDescriptionDetails(
    descriptionId: Description['descriptionId'],
    dto: DescriptionUpdateDTO
  ) {
    return this.client.put<Description>(`/descriptions/${descriptionId}`, dto);
  }

  createDescriptionPropertyCoding(
    descriptionId: Description['descriptionId'],
    dto: DescriptionPropertyCodingCreateDTO
  ) {
    return this.client.post<CategoricalPropertyCoding>(
      `/descriptions/${descriptionId}/PropertyCodings`,
      dto
    );
  }

  updateDescriptionPropertyCoding({
    descriptionId,
    dto,
    propertyTypeId,
    currentPropertyCodeId
  }: {
    descriptionId: Description['descriptionId'];
    dto: DescriptionPropertyCodingUpdateDTO;
    propertyTypeId: CommonPropertyType;
    currentPropertyCodeId?: string;
  }) {
    const query = queryString.stringify({
      typeId: propertyTypeId,
      existingPropertyCodeId: currentPropertyCodeId
    });

    return this.client.put<CategoricalPropertyCoding>(
      `/descriptions/${descriptionId}/PropertyCodings?${query}`,
      dto
    );
  }

  deleteDescriptionPropertyCoding({
    descriptionId,
    propertyTypeId,
    propertyCodeId
  }: {
    descriptionId: Description['descriptionId'];
    propertyTypeId: CommonPropertyType;
    propertyCodeId?: string;
  }) {
    const query = queryString.stringify({
      typeId: propertyTypeId,
      codeId: propertyCodeId
    });

    return this.client.delete<CategoricalPropertyCoding[]>(
      `/descriptions/${descriptionId}/PropertyCodings?${query}`
    );
  }

  updateStudyDetails(studyId: string | number, dto: StudyUpdateDTO) {
    return this.client.put<Study>(`/studies/${studyId}`, dto);
  }

  loadEventCodings(descriptionId: Description['descriptionId']) {
    return this.client.get<EventCoding[]>(
      `/descriptions/${descriptionId}/eventcodings`
    );
  }

  deleteEventCodings(
    descriptionId: Description['descriptionId'],
    eventCodingIds: Array<EventCoding['eventCodingId']>
  ) {
    const query = queryString.stringify(
      {
        eventCodingIds
      },
      { arrayFormat: 'comma' }
    );

    return this.client.delete<GenericEventTree>(
      `/descriptions/${descriptionId}/eventcodings?${query}`
    );
  }

  updateEventCodings({
    dto,
    descriptionId,
    newEventCodeId
  }: {
    descriptionId: number;
    newEventCodeId: number;
    dto: EventCodingUpdateDTO[];
  }) {
    const query = queryString.stringify(
      {
        newEventCodeId
      },
      { arrayFormat: 'comma' }
    );

    return this.client.put<GenericEventTree>(
      `/descriptions/${descriptionId}/eventcodings?${query}`,
      dto
    );
  }

  mergeEventCodings({
    descriptionId,
    destinationEventCodingId,
    sourceEventCodingIds
  }: {
    descriptionId: Description['descriptionId'];
    destinationEventCodingId: EventCoding['eventCodingId'];
    sourceEventCodingIds: Array<EventCoding['eventCodingId']>;
  }) {
    return this.client.post<GenericEventTree>(
      `/descriptions/${descriptionId}/eventcodings/${sourceEventCodingIds.join(
        ','
      )}/mergeWith/${destinationEventCodingId}`
    );
  }

  updateEventCodingsSortOrder({
    dto,
    descriptionId
  }: {
    descriptionId: number;
    dto: EventCodingUpdateDTO[];
  }) {
    return this.client.patch<EventCoding[]>(
      `/descriptions/${descriptionId}/eventcodings/patch-sortOrder`,
      dto
    );
  }

  createEventCoding({
    descriptionId,
    dto
  }: {
    descriptionId: number;
    dto: EventCodingCreateDTO;
  }) {
    return this.client.post<GenericEventTree>(
      `/descriptions/${descriptionId}/eventcodings`,
      dto
    );
  }

  deleteEvents(
    descriptionId: Description['descriptionId'],
    eventIds: Array<Event['eventId']>
  ) {
    const query = queryString.stringify({
      eventIds
    });

    return this.client.delete<Event[]>(
      `/descriptions/${descriptionId}/events?${query}`
    );
  }

  deleteEEGMarkersFromhiSCORE(
    descriptionId: Description['descriptionId'],
    eventIds: Array<Event['eventId']>
  ) {
    return this.client.delete<number[]>(
      `/descriptions/${descriptionId}/events/${eventIds.join(',')}`
    );
  }

  updateEvents({
    descriptionId,
    eventIds,
    eventCodingId
  }: {
    descriptionId: number;
    eventIds: Array<Event['eventId']>;
    eventCodingId: EventCoding['eventCodingId'];
  }) {
    const query = queryString.stringify({
      eventId: eventIds.reduce(
        (acc, id) => (acc ? `${acc},${id}` : `${id}`),
        ''
      ),
      eventCodingId
    });

    return this.client.put<Event[]>(
      `/descriptions/${descriptionId}/events?${query}`
    );
  }

  loadEventPropertyCodings(
    descriptionId: Description['descriptionId'],
    eventCodingId: EventCoding['eventCodingId']
  ) {
    return this.client.get<EventPropertyCoding>(
      `/descriptions/${descriptionId}/eventcodings/${eventCodingId}/eventPropertyCodings`
    );
  }

  updateEventPropertyCodings(
    descriptionId: Description['descriptionId'],
    eventCodingId: EventCoding['eventCodingId'],
    dto: EventPropertyCodingUpdateDTO
  ) {
    return this.client.post<UpdatedEventPropertyCoding>(
      `/descriptions/${descriptionId}/eventcodings/${eventCodingId}/eventPropertyCodings`,
      dto
    );
  }

  loadHeadModels(descriptionId: Description['descriptionId']) {
    return this.client.get<EventCodingHeadModel[]>(
      `/descriptions/${descriptionId}/eventCodingHeadModel`
    );
  }

  loadHeadModelByEventCodingId(
    descriptionId: Description['descriptionId'],
    eventCodingId: EventCoding['eventCodingId']
  ) {
    return this.client.get<EventCodingHeadModel>(
      `/descriptions/${descriptionId}/eventCodingHeadModel/${eventCodingId}?templateId=${HeadModelTemplate.LocationProperties}`
    );
  }

  loadReportHeadModel(
    descriptionId: Description['descriptionId'],
    templateId: HeadModelTemplate
  ) {
    return this.client.get<string>(
      `/descriptions/${descriptionId}/reportHeadModel/${templateId}`
    );
  }

  loadDescriptionStatus(descriptionId: Description['descriptionId']) {
    return this.client.get<DescriptionStatus>(
      `/descriptions/${descriptionId}/descriptionStatus`
    );
  }

  completeDescription(
    descriptionId: Description['descriptionId'],
    payload: {
      data: ReportDataFacade;
      schema: BaseBlock;
    }
  ) {
    return this.client.post<Description>(
      `/descriptions/${descriptionId}/complete`,
      {
        ReportDtoJson: JSON.stringify(payload)
      }
    );
  }

  reopenDescription(descriptionId: Description['descriptionId']) {
    return this.client.put<Description>(
      `/descriptions/${descriptionId}/reopen`
    );
  }

  deleteDescription(descriptionId: Description['descriptionId']) {
    return this.client.delete<Report[]>(`/descriptions/${descriptionId}`);
  }

  loadShoppingCart(
    descriptionId: Description['descriptionId'],
    eventCodingIds?: Array<EventCoding['eventCodingId']>
  ) {
    const query = queryString.stringify(
      {
        eventCodingIds
      },
      { arrayFormat: 'comma' }
    );

    return this.client.get<ShoppingCart[]>(
      `/descriptions/${descriptionId}/shopping-cart?${query}`
    );
  }

  loadEEGMarkerTypeGroup(markerGroupId: number) {
    return this.client.get<EEGMarkerType[]>(`/EEGMarkerType/${markerGroupId}`);
  }
}

const instance = new StudyApi('/api/v1');

export { instance as StudyApi };

import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form/dist/index.ie11';
import { useParams } from 'react-router-dom';
import {
  ErrorTheme,
  ErrorTitles,
  FormSection,
  GeneralError,
  KeyValueRow,
  KeyValueRowDirection,
  useCustomTranslation
} from '@holberg/ui-kit';
import { StudyNeonatalContainer } from 'components/StudyNeonatalContainer';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { Description } from 'entities/Description.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { Report } from 'entities/Report.entity';
import { Study } from 'entities/Study.entity';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { PatientPropertyTypes } from 'enums/PatientPropertyType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useAutofocus } from 'hooks/useAutofocus';
import { useCurrentSessionHasLock } from 'hooks/useCurrentSessionHasLock';
import { observer } from 'mobx-react-lite';
import { buildFormShape } from 'utils/formHelpers';

import { StudyConditionsForm } from '../StudyConditionsForm';
import { StudyReferralInfoForm } from '../StudyReferralInfoForm';

import styles from './StudyDetails.module.scss';

const patientPropertyTypes = Object.freeze([
  PatientPropertyTypes.Medication,
  PatientPropertyTypes.ClinicalDiagnosis,
  PatientPropertyTypes.EpisodeFrequency,
  PatientPropertyTypes.LastEpisode,
  PatientPropertyTypes.BrainMRI,
  PatientPropertyTypes.FunctionalNeuroimaging,
  PatientPropertyTypes.MedicationAdministered,
  PatientPropertyTypes.MedicationWithdrawal
]);

const descriptionPropertyTypes = Object.freeze([
  DescriptionPropertyTypes.IndicationForEEG,
  DescriptionPropertyTypes.EEGType,
  DescriptionPropertyTypes.Alertness,
  DescriptionPropertyTypes.Priority,
  DescriptionPropertyTypes.WorkingOrOffHours,
  DescriptionPropertyTypes.LevelOfCare
]);

const StudyDetailsForm: React.FC<{
  patientDetails: PatientDetails;
  reportDetails: Report;
  descriptionDetails: Description;
  studyPropertyCodings: Record<number, CategoricalPropertyCoding[]>;
  patientPropertyCodings: Record<number, CategoricalPropertyCoding[]>;
}> = observer(
  ({
    patientDetails,
    reportDetails,
    descriptionDetails,
    studyPropertyCodings = {},
    patientPropertyCodings = {}
  }) => {
    const { t } = useCustomTranslation();

    const descriptionsStore = useStore(StoreType.Descriptions);
    const { currentSessionHasLock } = useCurrentSessionHasLock();

    const readOnly =
      descriptionsStore.isReadOnly(descriptionDetails.descriptionId) ||
      !currentSessionHasLock(descriptionDetails.patientId);

    const studyCodings = useMemo(() => buildFormShape(studyPropertyCodings), [
      studyPropertyCodings
    ]);
    const patientCodings = useMemo(
      () => buildFormShape(patientPropertyCodings),
      [patientPropertyCodings]
    );

    const methods = useForm<{
      patientDetails: PatientDetails;
      descriptionDetails: Description;
      studyDetails?: Study;
      reportDetails: Report;
      studyCodings: Record<number, CategoricalPropertyCoding['formShape'][]>;
      patientCodings: Record<number, CategoricalPropertyCoding['formShape'][]>;
    }>({
      defaultValues: {
        descriptionDetails,
        reportDetails,
        patientDetails,
        patientCodings,
        studyCodings
      },
      mode: 'onTouched',
      reValidateMode: 'onChange',
      shouldUnregister: false
    });

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(() => {})} className={styles.form}>
          {descriptionDetails.isNeonatal && (
            <FormSection title={t('Neonatal age')}>
              <StudyNeonatalContainer readOnly={readOnly} />
            </FormSection>
          )}
          <FormSection title={t('Referral information')}>
            <StudyReferralInfoForm readOnly={readOnly} />
          </FormSection>
          <FormSection title={t('Study conditions')}>
            <StudyConditionsForm readOnly={readOnly} />
          </FormSection>
        </form>
      </FormProvider>
    );
  }
);

export const StudyDetails: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { t } = useCustomTranslation();

  const patientDetailsStore = useStore(StoreType.PatientDetails);
  const descriptionsStore = useStore(StoreType.Descriptions);
  const reportsStore = useStore(StoreType.PatientReports);
  const findingsStore = useStore(StoreType.Findings);
  const patientPropertyTypeCodesStore = useStore(
    StoreType.PatientPropertyTypeCodes
  );
  const descriptionPropertyTypeCodesStore = useStore(
    StoreType.DescriptionPropertyTypeCodes
  );
  const patientPropertyTypesStore = useStore(StoreType.PatientPropertyTypes);
  const descriptionPropertyTypesStore = useStore(
    StoreType.DescriptionPropertyTypes
  );
  const messagesStore = useStore(StoreType.Messages);

  const reportDetails = reportsStore.patientReports.get(Number(id))!;
  const descriptionDetails = descriptionsStore.descriptionById(Number(id))!;
  const patientId = String(descriptionDetails.patientId);
  const patientDetails = patientDetailsStore.patientById(patientId);

  const ref = useAutofocus<HTMLDivElement>(patientDetails.patientId);

  const activeDescriptionId = descriptionDetails.descriptionId;

  useEffect(() => {
    for (const propertyType of patientPropertyTypes) {
      patientPropertyTypeCodesStore.loadPropertyTypesCodes({
        propertyType,
        ageConstraint: descriptionDetails.ageConstraints
      });
    }
    for (const propertyType of descriptionPropertyTypes) {
      descriptionPropertyTypeCodesStore.loadPropertyTypesCodes({
        propertyType,
        ageConstraint: descriptionDetails.ageConstraints
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionDetails]);

  useEffect(() => {
    return () => {
      patientDetailsStore.clearRetryRequests();
      descriptionsStore.clearAllRequests();
      messagesStore.clearSystemMessages();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    descriptionPropertyTypesStore.loadPropertyTypes(
      descriptionDetails.ageConstraints
    );
    patientPropertyTypesStore.loadPropertyTypes(
      descriptionDetails.ageConstraints
    );
    findingsStore.loadEventCodes(descriptionDetails.ageConstraints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionDetails.ageConstraints]);

  useEffect(() => {
    findingsStore.loadShoppingCarts(activeDescriptionId);
  }, [activeDescriptionId, findingsStore]);

  const renderHeaderRow = useCallback(
    (title: string, value?: string | number) =>
      !!value && (
        <KeyValueRow
          title={t(title)!}
          value={value}
          className={styles.row}
          direction={KeyValueRowDirection.Vertical}
          reverse
          titleClassName={styles['row-title']}
        />
      ),
    [t]
  );

  const error =
    patientDetailsStore.categoricalPropertyCodingsError ||
    patientPropertyTypesStore.propertyTypesError ||
    descriptionPropertyTypesStore.propertyTypesError;

  return (
    <>
      {error ? (
        <GeneralError theme={ErrorTheme.Secondary} title={ErrorTitles.Load} />
      ) : (
        !!patientDetails.patientId && (
          <div className={styles['tab-content']} ref={ref} tabIndex={1}>
            <div className={styles['content-header']}>
              {renderHeaderRow('Start', reportDetails.reportStartDateTime)}
              {renderHeaderRow('Stop', reportDetails.reportStopDateTime)}
              {renderHeaderRow('Total time span', reportDetails.totalTime)}
              {renderHeaderRow('Recorded time span', '')}
            </div>
            <StudyDetailsForm
              key={id}
              patientDetails={patientDetails}
              reportDetails={reportDetails}
              descriptionDetails={descriptionDetails}
              studyPropertyCodings={descriptionsStore.propertyCodings(
                parseInt(id)
              )}
              patientPropertyCodings={patientDetailsStore.propertyCodings(
                patientId
              )}
            />
          </div>
        )
      )}
    </>
  );
});

import { computed } from 'mobx';
import { deserialize, list, object, serializable } from 'serializr';
import { dateDifference } from 'utils/dateHelpers';

import { Description } from './Description.entity';
import { InformationModel } from './InformationModel.entity';
import { Study } from './Study.entity';

export class Report {
  @serializable(object(Description))
  description!: Description | null;

  @serializable(list(object(Study)))
  studies!: Study[];

  @serializable(object(InformationModel))
  totalRecordingTimeForReport: InformationModel = new InformationModel();

  @computed
  get totalTime() {
    return this.studies.length
      ? dateDifference(
          new Date(this.studies[this.studies.length - 1].studyStop.eitherValue),
          new Date(this.studies[0].studyStart.eitherValue),
          true
        )
      : '';
  }

  get reportStartDate(): string {
    return this.studies.length ? this.studies[0].formattedStudyStart : '';
  }

  get reportStartDateTime(): string {
    return this.studies.length
      ? this.studies[0].formattedStudyStartDateTime
      : '';
  }

  get reportStopDateTime(): string {
    return this.studies.length
      ? this.studies[this.studies.length - 1].formattedStudyStopDateTime
      : '';
  }

  get reportStopDate(): string {
    return this.studies.length
      ? this.studies[this.studies.length - 1].formattedStudyStop
      : '';
  }

  static deserialize(json: Object | string) {
    return deserialize(Report, json);
  }
  static deserializeAsList(list: Report[]): Report[] {
    return list.map(Report.deserialize);
  }
}
